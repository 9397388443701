<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-card>
      <v-card-title>
        <v-toolbar flat dense>
          <v-btn icon elevation="2" color="green" @click="assessmentOverview()">
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-btn>
          <v-toolbar-title>Assessments </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <!-- Delete Dialog Confirmation-->
          <v-dialog v-model="dialogDel" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteAssessment">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--End Delete Dialog Confirmation-->
        </v-toolbar>
      </v-card-title>
      <v-card-subtitle>
        <br />
        Select Assessment to View Status
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="assessmentlist"
          :items-per-page="10"
          class="elevation-1"
          single-select
          :search="search"
          v-model="selLearner"
          group-by="sy"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" color="primary" @click="selRow(item)">
              mdi-pencil
            </v-icon>
            <v-icon small color="error" @click="dialogDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item._date="{ item }">
            <span>{{ new Date(item._date).toLocaleDateString("en-US") }}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <span>{{ new Date(item.updated_at).toLocaleDateString("en-US") }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <div class="py-3" />
  </v-container>
</template>

<script>
import { sync, dispatch } from "vuex-pathify";

export default {
  // name: "AssessmentList",

  data() {
    return {
      dialogDel: false,
      headers: [
      { text: "SY", value: "sy" },
        { text: "Date", value: "_date" },
        { text: "Program Id", value: "program.name" },
        { text: "School", value: "school.name" },
        { text: "Assessment Status", value: "current_assessment_status._status"},
        {text:"Updated by",value:'updatedby.name'},
        {text:"Last Update",value:'updated_at'},
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      item:[],
    };
  },

  watch: {
  
    selLearner(val) {
      this.selLearner = val[0];
      this.$router.push("/assessmentlist");
    },
  },

  beforeMount() {
    this.loadData();
  },

  computed: {
    ...sync("assessment", ["assessmentlist", "el", "assessmentStatus",'newassessment']),
    ...sync("learners", ["selLearner"]),
    ...sync('user',["userlogin"])
  },

  methods: {
    async selRow(item) {
      //get Assessment Data
      this.newassessment=false
      await this.getSelAsssessment(item.id);
     
      this.$router.push({ name: "Assessment Overview" });
    },



    async loadData() {
      if(!this.userlogin.approver && !this.userlogin.super_user){
        await dispatch("assessment/loadAssessmentList",this.userlogin.id);
      }else{
        await dispatch("assessment/loadAssessmentListApprover");
      }
      await dispatch("assessment/clearValues");
      await dispatch("program/loadData");
      await dispatch("school/loadData");
    },

    async deleteAssessment() {
      
      await dispatch("assessment/deleteAssessment", this.item);
      this.$toast.success("Successfully Deleted");
      await this.loadData();
      await dispatch("assessment/clearValues");
      this.dialogDel=false;
    },

    assessmentOverview(){
      this.newassessment=true
      this.el='1'
      this.$router.push('/assessmentoverview')
     
    },

    //open Delete Dialog
    dialogDelete(item) {
      this.item=item;
      this.dialogDel=true;
    },
    //close Delete Dialog Confirmation
    closeDelete(){
      this.dialogDel=false;
    },

    async getSelAsssessment(id) {
      await dispatch("assessment/getAssessmentStatus", id);
      await dispatch("assessment/loadAssessment_id", id);
      await dispatch("assessment/loadLearners", id);

      if (this.assessmentStatus._status == "PRE TEST") {
        this.el = "2";
      }else if (this.assessmentStatus._status == "PRETEST APPROVED") {
        this.el = "3";
      } else if (this.assessmentStatus._status == "POSTTEST APPROVAL") {
        this.el = "4";
      } else if (this.assessmentStatus._status == "APPROVED") {
        this.el = "5";
      } else if (this.pretestAssessment == null) {
        this.el = "1";
      } else {
        this.el = "1";
      }
    },
  },
};
</script>
